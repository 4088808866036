import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { MemberProfileService } from '../../member-portal/shared/services/member-profile.service';
import {map} from 'rxjs/operators';

export const TosGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

    const router: Router = inject(Router);
    const config: Configuration = inject(Configuration);

    return canUserAccessRoute()
        .pipe(
            map(
            res => {
                if (!res) {
                    router.navigate([config.TermsOfUseRoute]);
                    return false;
                }
                else {
                    return true;
                }
            },
            err => {
                return false;
            })
        );
}

function canUserAccessRoute(): Observable<boolean> {

    const memberProfileService: MemberProfileService = inject(MemberProfileService);
    
    return memberProfileService.getMemberProfile()
        .pipe(
        map(res => {
            return res.HasAcknowledgedTermsOfUse;
        }));
}